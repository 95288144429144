<script>
    export let setModalOpenObj;
    import { Link } from "svelte-routing";
</script>

<div class="footer-container" >
    <div class="footer-header">
        <a href="https://www.276holdings.com" target="_blank" rel="noreferrer"> 회사소개 </a>
        <a href="/" on:click|preventDefault={setModalOpenObj('isTermsOfServiceModalOpen', true)}> 이용약관 </a>
        <Link to="/esg">ESG</Link>
        <!--<a href="/" on:click|preventDefault={setModalOpenObj('isParnershipModalOpen', true)}> 제휴문의 </a>--> <!--  API CORS 해결 후 구현 예청 -->
        <!--<a href="/" on:click|preventDefault={setModalOpenObj('isMarketingPolicyModalOpen', true)}> 마케팅 정보 수신동의 </a>-->
    </div>
    <div class="footer-body" >
        <p class="company-name">(주)276홀딩스</p>
        <p>
            대표 : 신인근 | 사업자등록번호 : 732-86-01599 | 개인정보보호책임자 : 박진용<br/>
            고객센터 : T. 02-785-7080 / E. contact@276holdings.com<br/>
            주소: 서울특별시 영등포구 국회대로70길 19, 511호(대하빌딩)<br/>
        </p>
        <p>
            276홀딩스는 사실 기반의 정보를 제공하기 위해 노력하며 정보 제공에 따른 법적 책임을 지지 않습니다.<br/>
            Copyright © 276 HOLDINGS INC. All rights reserved.
        </p>
    </div>
</div>